.background {
    background-color: #EDF3F3;
    margin: 30px 58px 0 58px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
}

.background::before {
    content: "";
    display: block;
    background: url("/src/assets/body/top-background.png") no-repeat;
    background-size: cover;
    height: 22px;
    transform: translateY(-8px);
}

.background::after {
    content: "";
    display: block;
    background: url("/src/assets/body/bottom-background.png") no-repeat;
    background-size: cover;
    height: 22px;
    transform: translateY(18px);
    position: absolute;
    width: 100%;
    bottom: 0;
}

.background__logo {
    height: 78px;
    position: absolute;

    /*TOP IS NEGATIVE HALF OF ITEM HEIGHT*/
    top: -40px;

    /*RIGHT IS HALF OF PARENT WIDTH MINUS HALF OF ELEMENT WIDTH*/
    right: calc(50% - 144px);
}

.background__title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 42px;
}

.background__title-text {
    font-family: BebasNeue, serif;
    font-size: 28px;
    color: #4C687B;
    margin-top: 8px;
}

.background__container {
    padding: 36px 32px 0 32px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.modal-container-qr {
    z-index: 2;
    position: absolute;
    background: url("/src/assets/modal/modal-background.png") no-repeat center center / contain;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 500px;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 60px;
}

.modal-content-qr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    font-weight: bold;
}

.link-qr {
    width: 50%;
    margin-bottom: 10px;
}
