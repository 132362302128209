

h2 {
    color: #959595;
    font-family: BebasNeue, Arial, sans-serif;
}

.rsis-container div {
    background-size: contain !important;
    background-repeat: no-repeat;
    background-position: center;
}

.slider {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.slider-fullscreen {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 50;
    top: 0;
    bottom: 0;
}

.downloadFile {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    flex-direction: column;
}
