.container {
    background: url("/src/assets/affirmation/affirmation_card_big.png") no-repeat;
    background-size: 100% 100%;
    aspect-ratio: 641/100;
    max-width: 800px;
    width: 100%;
    padding: 20px 44px;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.completed {
    background: url("/src/assets/affirmation/affirmation_card_big_done.png") no-repeat;
    background-size: 100% 100%;
    aspect-ratio: 641/100;
    max-width: 800px;
}

.container__title {
    font-family: BebasNeue;
    color: #ffffff;
    font-size: 32px;
}

.container__text {
    color: #ffffff;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    box-sizing: border-box;
}
