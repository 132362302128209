.body-wrapper {
    background-image: url("/src/assets/body/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    min-height: 100vh;
    overflow: hidden;
}

.links {
    color: white;
    font-size: 14px;
    text-decoration: underline;
    margin: 4px 4px;
    cursor: pointer;
}
