.container-vod {
    background: url("/src/assets/mito-card.png") no-repeat;
    background-size: contain;
    height: 205px;
    width: 300px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: default;
    transition: 0.3s all;
}

.verdad {
    background: url("/src/assets/verdad-card.png") no-repeat;
    background-size: contain;
}

.selected {
    height: calc(300px * 0.6859);
    width: 300px;
}

.not-selected {
    height: calc(260px * 0.6859);
    width: 260px;
    opacity: 0.7;
}

.card-text-vod {
    font-size: 48px;
    font-weight: 400;
    font-family: BebasNeue;
    color: white;
}
.card-result-vod {
    font-size: 20px;
    font-family: Barlow;
    color: white;
}
