.card-active {
    background-size: cover;
    background-image: url("/src/assets/selectors/selector-active.png");
    height: 214px;
    width: 312px;
    /*border-radius: 12px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
}

.card-disabled {
    background-size: cover;
    background-image: url("/src/assets/selectors/selector-default.png");
    height: 180px;
    width: 262px;
    /*border-radius: 12px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s all;
}

.card-text {
    font-size: 48px;
    font-weight: 400;
    font-family: BebasNeue;
    color: white;
}

.--text-disabled {
    opacity: 0.5
}
